import { useEffect, useRef } from 'react';
import { dequal } from "./dequal.js";
import { useInstantSearchContext } from "./useInstantSearchContext.js";
import { useIsomorphicLayoutEffect } from "./useIsomorphicLayoutEffect.js";
export function useWidget(_ref) {
  var widget = _ref.widget,
    parentIndex = _ref.parentIndex,
    props = _ref.props,
    shouldSsr = _ref.shouldSsr;
  var prevPropsRef = useRef(props);
  useEffect(function () {
    prevPropsRef.current = props;
  }, [props]);
  var prevWidgetRef = useRef(widget);
  useEffect(function () {
    prevWidgetRef.current = widget;
  }, [widget]);
  var cleanupTimerRef = useRef(null);
  var shouldAddWidgetEarly = shouldSsr && !parentIndex.getWidgets().includes(widget);
  var search = useInstantSearchContext();

  // This effect is responsible for adding, removing, and updating the widget.
  // We need to support scenarios where the widget is remounted quickly, like in
  // Strict Mode, so that we don't lose its state, and therefore that we don't
  // break routing.
  useIsomorphicLayoutEffect(function () {
    var previousWidget = prevWidgetRef.current;

    // Scenario 1: the widget is added for the first time.
    if (!cleanupTimerRef.current) {
      if (!shouldAddWidgetEarly) {
        parentIndex.addWidgets([widget]);
      }
    }
    // Scenario 2: the widget is rerendered or updated.
    else {
      // We cancel the original effect cleanup because it may not be necessary if
      // props haven't changed. (We manually call it if it is below.)
      clearTimeout(cleanupTimerRef.current);

      // Warning: if an unstable function prop is provided, `dequal` is not able
      // to keep its reference and therefore will consider that props did change.
      // This could unsollicitely remove/add the widget, therefore forget its state,
      // and could be a source of confusion.
      // If users face this issue, we should advise them to provide stable function
      // references.
      var arePropsEqual = dequal(props, prevPropsRef.current);

      // If props did change, then we execute the cleanup function instantly
      // and then add the widget back. This lets us add the widget without
      // waiting for the scheduled cleanup function to finish (that we canceled
      // above).
      if (!arePropsEqual) {
        parentIndex.removeWidgets([previousWidget]);
        parentIndex.addWidgets([widget]);
      }
    }
    return function () {
      // We don't remove the widget right away, but rather schedule it so that
      // we're able to cancel it in the next effect.
      cleanupTimerRef.current = setTimeout(function () {
        search._schedule(function () {
          if (search._preventWidgetCleanup) return;
          parentIndex.removeWidgets([previousWidget]);
        });
      });
    };
  }, [parentIndex, widget, shouldAddWidgetEarly, search, props]);
  if (shouldAddWidgetEarly) {
    parentIndex.addWidgets([widget]);
  }
}